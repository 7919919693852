<template>
  <div class="text-black flex" v-if="dataFetched">
    <Sidebar />
    <div class="font-semibold flex-1 h-screen">
      <Navbar title="Reports - Journeys" />
      <div id="reports-journeys-content" class="m-4 p-7 flex flex-wrap gap-4">
        <div v-for="kit in data.journeys" :key="kit.name" class="shadow">
          <div class="flex">
            <div class="grid bg-nColorBlue rounded-l w-56 h-40">
              <div class="h-28 text-xl text-white font-semibold p-4 break-all">
                {{ kit.name }}
                <div class="uppercase text-xs text-gray-100 font-semibold pt-2">
                  created on {{ kit.created_on }}
                </div>
              </div>
            </div>
            <div
              class="bg-white rounded-r w-60 h-40 flex flex-wrap items-center relative"
            >
              <div
                class="text-base text-gray-500 font-semibold p-6 pb-0 break-all"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-explicit inline-block"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M6.826 10.88H10.5V12h-5V4.002h5v1.12H6.826V7.4h3.457v1.073H6.826v2.408Z"
                  />
                  <path
                    d="M2.5 0A2.5 2.5 0 0 0 0 2.5v11A2.5 2.5 0 0 0 2.5 16h11a2.5 2.5 0 0 0 2.5-2.5v-11A2.5 2.5 0 0 0 13.5 0h-11ZM1 2.5A1.5 1.5 0 0 1 2.5 1h11A1.5 1.5 0 0 1 15 2.5v11a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 13.5v-11Z"
                  />
                </svg>
                <span class="inline-block ml-2">
                  <span class="font-bold">{{ kit.experiences }}</span>
                  Experiences
                </span>
              </div>
              <div
                class="text-base text-gray-500 font-semibold p-6 pt-0 break-all"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-person-square inline-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z"
                  />
                </svg>
                <span class="inline-block ml-2">
                  <span class="font-bold">{{ kit.users }}</span> Users
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Sidebar from "@/components/Common/Sidebar/Standard";
import Navbar from "@/components/Common/Navbar/Standard";

export default {
  name: "Reports",
  components: { Sidebar, Navbar },
  data() {
    return {
      dataFetched: false,
      data: null,
    };
  },
  mounted() {
    const vue = this;
    ApiService.get(apiResource.reports.getJourneys)
      .then(({ data }) => {
        vue.dataFetched = true;
        vue.data = data.data;
      })
      .catch(() => {});
  },
  methods: {},
};
</script>

<style scoped lang="postcss"></style>
